<template>
  <div id="user-list" class="car-brand">
    <CarBrandAddNew
      v-model="isAddNewCarBrandSidebarActive"
      :id="carBrandId"
      :type-options="typeOptions"
      @reset-id="carBrandId = null"
    ></CarBrandAddNew>

    <!-- list filters -->
    <v-card>
      <v-card-title class="d-flex">
        <div class="mr-auto">
          Car Brands
          <!-- <span class="car-brand-count mt-1 ml-1">({{ filteredCarBrands.length +  '/' + carBrands.length }})</span> -->
        </div>
        <div class="d-flex">
          <v-text-field
            v-model="searchQuery"
            placeholder="Search"
            outlined
            hide-details
            dense
            clearable
            class="text-search me-3 mb-4"
          >
          </v-text-field>
          <v-btn icon class="mb-4" @click="showFilters = !showFilters">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon size="24" class="me-1" v-bind="attrs" v-on="on">
                  {{ icons.mdiFilter }}
                </v-icon>
              </template>
              <span>Filters</span>
            </v-tooltip>
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon dark v-bind="attrs" v-on="on">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon size="24" class="me-1" v-bind="attrs" v-on="on">
                      {{ icons.mdiSort }}
                    </v-icon>
                  </template>
                  <span>Sort By</span>
                </v-tooltip>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in sortOptions" :key="index" @click="sortBy = item.value">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- <v-btn
            color="primary"
            class="mb-4 ml-3"
            @click.stop="addCarBrand"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            <span>Add</span>
          </v-btn> -->
        </div>
      </v-card-title>

      <!-- actions -->
      <v-card-text class="d-flex flex-wrap pb-0" v-if="showFilters">
        <!-- search -->
        <v-select
          v-model="statusFilter"
          placeholder="Select Status"
          :items="statusOptions"
          item-text="title"
          item-value="value"
          outlined
          clearable
          dense
          hide-details
          class="user-search me-3 mb-4"
        ></v-select>

        <v-select
          v-model="typeFilter"
          placeholder="Select Type"
          :items="typeOptions"
          item-text="title"
          item-value="value"
          outlined
          clearable
          dense
          hide-details
          class="user-search me-3 mb-4"
        ></v-select>

        <!-- <v-select
            v-model="sortBy"
            placeholder="Sort By"
            :items="sortOptions"
            item-text="title"
            item-value="value"
            outlined
            clearable
            dense
            hide-details
            class="user-search me-3 mb-4"
          ></v-select> -->
      </v-card-text>

      <p class="ml-2">{{ filteredCarBrands.length || 'No' }} records found</p>
      <v-row>
        <v-col cols="12" lg="3">
          <AddNewCard title="Add Car Brand" textSize="text-h6" @click="addCarBrand" />
        </v-col>
        <v-col
          cols="12"
          lg="3"
          v-for="({ id, description, brandimg, active, ltype, markltype, updatedon }, c) in paginatedCarBrands[page - 1]"
          :key="c"
        >
          <CarBrandCard
            :id="id"
            :brandName="description"
            :image="brandimg"
            :isActive="active"
            :type="country == 'UK' ? ltype : markltype"
            :updatedOn="formatDate(updatedon.toDate(), 'DD-MMM-YYYY hh:mm A')"
            @edit-carbrand="editCarBrand"
          />
        </v-col>
      </v-row>
      <div class="d-flex">
        <div class="mt-2 mr-auto ml-5">
          <span>Items per page: {{ itemsPerPage }} </span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon dark v-bind="attrs" v-on="on">
                {{ icons.mdiMenuDown }}
              </v-icon>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in itemsPerPageOptions" :key="index" @click="itemsPerPage = item.title">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="d-flex">
          <span class="mt-2">{{ countText }}</span>
          <v-pagination v-model="page" :length="paginatedCarBrands.length"></v-pagination>
        </div>
        ̉
      </div>
    </v-card>
  </div>
</template>

<script>
import { ref, onUnmounted, computed } from '@vue/composition-api'
import { mdiPlus, mdiChevronLeft, mdiChevronRight, mdiFilter, mdiSort, mdiMenuDown } from '@mdi/js'
import useCarBrand from './useCarBrand'
import useMoment from './../../../composables/useMoment'
import CarBrandCard from './CarBrandCard.vue'
import CarBrandAddNew from './CarBrandAddNew.vue'
import AddNewCard from '@/components/AddNewCard.vue'
import useAppConfig from '@/@core/@app-config/useAppConfig'

export default {
  components: {
    CarBrandCard,
    CarBrandAddNew,
    AddNewCard,
  },
  setup() {
    const { country } = useAppConfig()
    const isAddNewCarBrandSidebarActive = ref(false)
    const carBrandId = ref(null)
    const showFilters = ref(true)
    const statusOptions = [
      { title: 'Active', value: true },
      { title: 'Inactive', value: false },
    ]

    const typeOptions = [
      { title: 'Luxury', value: 'Luxury' },
      { title: 'Sports Car', value: 'Sports Car' },
      { title: 'Executive', value: 'Executive' },
      { title: 'Super Car', value: 'Super Car' },
      { title: 'Standard', value: 'Standard' },
      { title: 'Premium', value: 'Premium' },
    ]

    const sortOptions = [
      { title: 'Last Updated', value: { by: 'updatedon', order: 'desc' } },
      { title: 'First Updated', value: { by: 'updatedon', order: 'asc' } },
      { title: 'A to Z', value: { by: 'description', order: 'asc' } },
      { title: 'Z to A', value: { by: 'description', order: 'desc' } },
    ]

    const itemsPerPageOptions = [{ title: '16' }, { title: '32' }, { title: '48' }, { title: 'All' }]

    const {
      page,
      itemsPerPage,
      carBrands,
      filteredCarBrands,
      paginatedCarBrands,
      searchQuery,
      statusFilter,
      typeFilter,
      sortBy,
      loading,

      unsubscribeCarBrands,
    } = useCarBrand()

    const countText = computed(() => {
      if (paginatedCarBrands.value.length) {
        const previous = itemsPerPage.value == 'All' ? 0 : (page.value - 1) * itemsPerPage.value
        const start = previous + 1
        const end = previous + paginatedCarBrands.value[page.value - 1].length
        return `${start}-${end} of ${filteredCarBrands.value.length}`
      }
      return ''
    })

    const { formatDate } = useMoment()

    const addCarBrand = () => {
      carBrandId.value = null
      isAddNewCarBrandSidebarActive.value = !isAddNewCarBrandSidebarActive.value
    }

    const editCarBrand = id => {
      carBrandId.value = id
      isAddNewCarBrandSidebarActive.value = !isAddNewCarBrandSidebarActive.value
    }

    onUnmounted(() => {
      unsubscribeCarBrands()
    })

    return {
      page,
      itemsPerPage,
      carBrandId,
      showFilters,
      isAddNewCarBrandSidebarActive,
      statusOptions,
      typeOptions,
      carBrands,
      filteredCarBrands,
      paginatedCarBrands,
      searchQuery,
      statusFilter,
      typeFilter,
      sortBy,
      sortOptions,
      loading,
      formatDate,
      editCarBrand,
      addCarBrand,
      itemsPerPageOptions,
      countText,
      country,
      // icons
      icons: {
        mdiPlus,
        mdiChevronLeft,
        mdiChevronRight,
        mdiFilter,
        mdiSort,
        mdiMenuDown,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.car-brand {
  .car-brand-count {
    font-size: 1rem;
  }
  .v-input {
    max-width: 200px;
  }
  .text-search {
    min-width: 300px;
  }
}
</style>
