<template>
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary"> {{ id ? "Update" : "Add" }} Car Brand</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-user-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
          <v-text-field
            v-model="carBrandData.description"
            @keyup="() => carBrandData.description = capitalizeSentence(carBrandData.description)"
            outlined
            dense
            :rules="[validators.required]"
            label="Brand Name *"
            placeholder="Brand Name"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-select
            v-model="carBrandData[typeKey]"
            :rules="[validators.required]"
            label="Car Type *"
            :items="typeOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
            :disabled="id"
          >
          </v-select>

          <v-file-input
            accept="image/*"
            prepend-inner-icon="$file"
            prepend-icon=""
            label="Brand Logo *"
            placeholder="Brand Logo"
            outlined
            dense
            v-model="carBrandData.brandimg"
            @change="onFileChange"
          ></v-file-input>

          <v-avatar tile size="80" v-if="carBrandData.brandimg">
            <v-img contain :src="imageUrl"></v-img>
          </v-avatar>

          <v-switch
            v-model="carBrandData.active"
            inset
            :color="carBrandData.active ? 'success' : 'error'"
            :label="`${carBrandData.active ? 'Active' : 'Inactive'}`"
          ></v-switch>

          <v-alert dense text color="error" v-if="error">
            {{ error }}
          </v-alert>

          <v-btn
            color="primary"
            class="me-3"
            :loading="loading"
            type="submit"
          >
            {{ id ? "Update" : "Add" }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="resetcarBrandData"
          >
            Cancel
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose, mdiCircle } from '@mdi/js'
import { find } from 'lodash'
import { ref, watch } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import useCarBrand from './useCarBrand'
import { capitalizeSentence } from '@core/utils/filter'
import useAppConfig from '@/@core/@app-config/useAppConfig'

export default {
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    id: {
      type: String | null,
      required: true,
    },
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    typeOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { country } = useAppConfig()
    const typeKey = country.value == 'UK' ? 'ltype' : 'markltype'
    const fileRules = [v => !!v || 'File is required', v => (v && v.size > 0) || 'File is required']
    const valid = ref(false)
    const form = ref(null)
    const imageUrl = ref('')

    const { addCarBrand, carBrands, carBrandData, blankCarBrandData, error, loading, editCarBrand } = useCarBrand()

    const validate = () => {
      form.value.validate()
    }

    const resetForm = async () => {
      await form.value.reset()
      carBrandData.value = JSON.parse(JSON.stringify(blankCarBrandData))
    }

    const resetcarBrandData = () => {
      resetForm()
      error.value = null
      emit('reset-id')
      emit('update:is-add-new-user-sidebar-active', false)
    }

    const onSubmit = async () => {
      if (valid.value) {
        if (props.id) {
          await editCarBrand(props.id)
        } else {
          await addCarBrand()
        }
        if (!error.value) {
          resetcarBrandData()
        }
      } else {
        validate()
      }
    }

    const onFileChange = file => {
      if (!file) {
        return
      }
      createImage(file)
    }

    const createImage = file => {
      const reader = new FileReader()

      reader.onload = e => {
        imageUrl.value = e.target.result
      }
      reader.readAsDataURL(file)
    }

    watch(
      () => props.id,
      () => {
        if (props.id) {
          const carBrand = find(carBrands.value, ['id', props.id])
          carBrandData.value.active = carBrand.active
          carBrandData.value.description = carBrand.description
          carBrandData.value.brandimg = carBrand.brandimg
          if (country.value == 'UK') {
            carBrandData.value.ltype = carBrand.ltype
          } else {
            carBrandData.value.markltype = carBrand.markltype
          }
          imageUrl.value = carBrand.brandimg
        }
      },
      { immediate: true },
    )

    watch(
      () => props.isAddNewUserSidebarActive,
      () => {
        if (!props.isAddNewUserSidebarActive) {
          resetcarBrandData()
        }
      },
    )

    return {
      resetcarBrandData,
      form,
      onSubmit,
      carBrandData,
      valid,
      validate,
      error,
      fileRules,
      imageUrl,
      onFileChange,
      loading,
      capitalizeSentence,
      country,
      typeKey,
      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiCircle,
      },
    }
  },
}
</script>
